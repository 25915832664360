<template>
  <vs-popup class="popupAlert" :title="title" :active.sync="localShow">
    <div class="vx-row">
      <div class="vx-col w-1/5">
        <vs-icon :icon="icon" size="large" color="warning"></vs-icon>
      </div>
      <div class="vx-col w-4/5">
        <slot />
      </div>
    </div>
    <hr>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button color="secondary" class="ml-auto" @click="cancel">{{ cancelButtonText }}</vs-button>
          <vs-button class="ml-4" :color="actionButtonColor" @click="action">{{ actionButtonText }}</vs-button>
        </div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
export default {
  name: 'dialog-sig',
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    },
    actionButtonText: {
      String, default: 'Deletar'
    },
    actionButtonColor: {
      String, default: 'danger'
    },
    cancelButtonText: {
      String, default: 'Cancelar'
    },
    icon: {
      String, default: 'warning'
    }
  },

  data () {
    return {
      localShow: false
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
      }
    }
  },

  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style>
.popupAlert .vs-popup {
  width: 40% !important;
  border-color: #FF9F43 !important;
  border: solid;
  border-width: thin;
}
.popupAlert .vs-popup--header {
  border-color: #FF9F43 !important;
  border: solid;
  border-width: thin;
}
/* .popupAlert .vs-popup--title h3 {
  color: #ffffff
} */
</style>
